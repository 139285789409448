<template>
  <b-overlay :show="loading" class="row">
    <div class="col-12 c g">
      <div class="card">
        <div class="card-header">
          <h4 class="text-center">
            <i class="fa fa-users"></i>
            {{ e("users") }}
          </h4>
          <div class="text-end">
            <b-input-group>
              <button
                class="btn btn-danger"
                v-if="page == 0 && members.length == 0"
                style="border-radius: 0"
                @click="reload()"
              >
                <i class="fa fa-refresh"></i>
                {{ e("restore-default") }}
              </button>
              <button
                class="btn btn-primary"
                v-if="page != 0"
                style="border-radius: 0"
                @click="pPage()"
              >
                <i class="fa fa-arrow-right"></i>
                {{ e("previous") }}
              </button>
              <button
                class="btn btn-success"
                v-b-modal.modal-1
                style="border-radius: 0"
              >
                <i class="fa fa-search"></i>
                {{ e("filter") }}
              </button>
              <button
                class="btn btn-primary"
                v-if="members.length"
                style="border-radius: 0"
                @click="nextPage()"
              >
                <i class="fa fa-arrow-left"></i>
                {{ e("next") }}
              </button>
            </b-input-group>
          </div>
        </div>
        <div class="card-body">
          <div
            class="alert alert-warning text-center g"
            style="height: 150px"
            v-if="members.length == 0 && !loading"
          >
            <br /><br />
            <h1><i class="fa fa-warning"></i></h1>
            <h4>{{ e("no-results") }}.</h4>
          </div>
          <div class="col-12 table-responsive" v-if="members.length">
            <table class="table table-hover table-bordered table-striped">
              <thead>
                <th>
                  {{ e("name") }}
                </th>
                <th>
                  {{ e("number") }}
                </th>
                <th>
                  {{ e("username") }}
                </th>
                <th>
                  {{ e("settings-group") }}
                </th>
                <th v-if="user.branches">
                  {{ e("branche") }}
                </th>
                <th>
                  {{ e("fingerprint") }}
                </th>
                <th>
                  {{ e("options") }}
                </th>
              </thead>
              <tbody>
                <tr v-for="member in members" :key="member._id">
                  <td
                    class="text-primary"
                    style="cursor: pointer"
                    @click="overview(member._id)"
                  >
                    {{ member.name }}
                  </td>
                  <td
                    class="text-primary"
                    style="cursor: pointer"
                    @click="overview(member._id)"
                  >
                    {{ member.number }}
                  </td>
                  <td
                    class="text-primary"
                    style="cursor: pointer"
                    @click="overview(member._id)"
                  >
                    {{ member.username }}
                  </td>
                  <td>
                    {{ groupName(member.settings_group_id) }}
                  </td>
                  <td v-if="user.branches">
                    <span v-if="member.all_branches">
                      <span class="badge bg-success">كل الفروع</span>
                    </span>
                    <span v-if="!member.all_branches">
                      <span class="badge bg-primary">فروع مُحددة</span>
                    </span>
                  </td>
                  <td style="padding: 0px !important">
                    <span
                      v-if="member.fp"
                      class="badge bg-success"
                      style="
                        border-radius: 0px;
                        width: 100%;
                        height: 100% !important;
                        text-align: center;
                      "
                    >
                      <i class="fa fa-check"></i>
                      {{ e("yes") }}
                    </span>
                    <span
                      v-if="!member.fp"
                      class="badge bg-danger"
                      style="
                        border-radius: 0px;
                        width: 100%;
                        height: 100% !important;
                        text-align: center;
                      "
                    >
                      <i class="fa fa-times"></i>
                      {{ e("no") }}
                    </span>
                  </td>
                  <td>
                    <b-dropdown
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :text="e('options')"
                      variant="relief-secondary"
                    >
                      <b-dropdown-item @click="overview(member._id)">
                        <i class="fa fa-chart-pie text-success"></i>
                        {{ e("overview-user") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="editUser(member._id)">
                        <i class="fa fa-edit text-primary"></i>
                        {{ e("edit") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteUser(member._id)"
                        ><i class="fa fa-trash text-danger"></i>
                        {{ e("delete") }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-1" hide-footer>
      <div>
        <button class="btn btn-block btn-danger btn-lg" @click="reload()">
          <i class="fa fa-refresh"></i>
          {{ e("restore-default") }}</button
        ><br />
        <button
          class="btn btn-block btn-primary btn-lg"
          @click="filterByName()"
        >
          <i class="fa fa-user"></i>
          {{ e("filter-by-name") }}</button
        ><br />
        <button
          class="btn btn-block btn-primary btn-lg"
          @click="filterByNumber()"
        >
          <i class="fa fa-user"></i>
          {{ e("filter-by-number") }}</button
        ><br />
        <button
          @click="filterByPhone()"
          class="btn btn-block btn-primary btn-lg"
        >
          <i class="fa fa-phone"></i>
          {{ e("filter-by-phone") }}</button
        ><br />
        <button
          @click="filterByGroup()"
          class="btn btn-block btn-primary btn-lg"
        >
          <i class="fa fa-cogs"></i>
          {{ e("filter-by-group") }}</button
        ><br />
        <button
          @click="filterByBranche()"
          v-if="user.branches"
          class="btn btn-block btn-primary btn-lg"
        >
          <i class="fa fa-link"></i>
          {{ e("filter-by-branche") }}
        </button>
      </div>
    </b-modal>
    <b-modal id="modal-2" hide-footer>
      <div>
        <div class="form-group">
          <label for="">{{ e("settings-group") }}</label>
          <select
            class="form-control"
            @change="filterByGroupNow()"
            v-model="filter.value"
          >
            <template v-for="group in groups">
              <option :key="group._id" :value="group._id">
                {{ group.title }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-3" hide-footer>
      <div>
        <div class="text-center g">
          <button
            class="btn btn-primary btn-sm"
            @click="filterByBrancheNowALL()"
          >
            {{ e("filter-by-all-branches") }}
          </button>
        </div>
        <div class="form-group">
          <label for="">{{ e("branche") }}</label>
          <select
            class="form-control"
            @change="filterByBrancheNow()"
            v-model="filter.value"
          >
            <template v-for="branche in user.branches_list">
              <option :key="branche.id" :value="branche.id">
                {{ branche.title }}
              </option>
            </template>
          </select>
        </div>
      </div>
    </b-modal>
  </b-overlay>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import {
  BLink,
  BOverlay,
  BFormCheckbox,
  BInputGroup,
  VBModal,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
export default {
  components: {
    BLink,
    BOverlay,
    BFormCheckbox,
    VBModal,
    BInputGroup,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      loading: true,
      user: JSON.parse(localStorage.getItem("user")),
      groups: [],
      members: [],
      perPage: 30,
      page: 0,
      filter: { type: null },
    };
  },
  created() {
    var g = this;
    $.post(api + "/general/settings-groups/index", {
      jwt: g.user.jwt,
    })
      .then(function (r) {
        g.groups = JSON.parse(r);
        g.getUsers();
      })
      .catch(function (e) {
        g.$toast({
          component: ToastificationContent,
          props: {
            title: "Error: " + e,
            icon: "TimesIcon",
            variant: "danger",
          },
        });
      });
  },
  methods: {
    e(d) {
      return e(d);
    },
    pPage() {
      this.page = this.page - 1;
      this.getUsers();
    },
    nextPage() {
      this.page = this.page + 1;
      this.getUsers();
    },
    getUsers() {
      var g = this;
      g.loading = true;
      $.post(api + "/users/index", {
        jwt: g.user.jwt,
        filter: g.filter,
        perPage: this.perPage,
        page: this.page,
      })
        .then(function (r) {
          g.members = JSON.parse(r).response;
          g.loading = false;
        })
        .catch(function (e) {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "Error: " + e,
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
    groupName(id) {
      var title = null;
      this.groups.forEach((element) => {
        if (element._id == id) {
          title = element.title;
        }
      });
      return title;
    },
    filterByName() {
      var f = prompt(e("filter-by-name"), "");
      if (f != null && f != "") {
        this.$bvModal.hide("modal-1");
        this.filter.type = "name";
        this.filter.value = f;
        this.page = 0;
        this.getUsers();
      }
    },
    filterByNumber() {
      var f = prompt(e("filter-by-number"), "");
      if (f != null && f != "") {
        this.$bvModal.hide("modal-1");
        this.filter.type = "number";
        this.filter.value = f;
        this.page = 0;
        this.getUsers();
      }
    },
    filterByPhone() {
      var f = prompt(e("filter-by-phone"), "");
      if (f != null && f != "") {
        this.$bvModal.hide("modal-1");
        this.filter.type = "phone";
        this.filter.value = f;
        this.page = 0;
        this.getUsers();
      }
    },
    filterByGroup() {
      var g = this;
      this.$bvModal.hide("modal-1");
      setTimeout(() => {
        g.$bvModal.show("modal-2");
      }, 100);
    },
    filterByGroupNow() {
      this.filter.type = "group";
      this.$bvModal.hide("modal-2");
      this.page = 0;
      this.getUsers();
    },
    filterByBranche() {
      var g = this;
      this.$bvModal.hide("modal-1");
      setTimeout(() => {
        g.$bvModal.show("modal-3");
      }, 100);
    },
    filterByBrancheNowALL() {
      this.filter.type = "all_branches";
      this.$bvModal.hide("modal-3");
      this.page = 0;
      this.getUsers();
    },
    filterByBrancheNow() {
      this.filter.type = "branche";
      this.$bvModal.hide("modal-3");
      this.page = 0;
      this.getUsers();
    },
    reload() {
      this.$bvModal.hide("modal-1");
      this.filter.type = null;
      this.filter.value = "";
      this.page = 0;
      this.getUsers();
    },
    editUser(id) {
      this.$router.push("/users/edit/" + id);
    },
    overview(id) {
      this.$router.push("/users/overview/" + id);
    },
    deleteUser(id) {
      var g = this;
      if (confirm(e("confirm"))) {
        $.post(api + "/users/delete", {
          jwt: this.user.jwt,
          id: id,
        })
          .then(function (r) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e("done"),
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.getUsers();
          })
          .catch(function (e) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "Error: " + e,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          });
      }
    },
  },
};
</script>